<template>
    <div class="connection-page">
        <div class="banner vivify fadeIn" v-if="bannerList.length>0">
            <img :src="bannerList[0].banner">
        </div>
        <div class="mt-100 mt-0">
            <div class="contant1 mt-0">
                <div class="title-text d-n">
                    <div class="text1">{{ $t('connection.us') }}</div>
                </div>
                <div class="valueBox">
                    <div class="inputBox">
                        <img src="./img/search1.png">
                        <div class="searchBox">
                            {{ $store.state.fromObj.website }}
                        </div>
                    </div>
                    <div class="text2 ">
                        <div>
                            {{ $t('connection.telephone') }}：{{ $store.state.fromObj.tel }}
                        </div>
                        <div>
                            {{ $t('connection.wechat') }}：{{ $store.state.fromObj.wechat }}
                        </div>
                    </div>
                    <div class="text2 ">
                        <div>
                            {{ $t('connection.phone') }}：{{ $store.state.fromObj.cell_phone }}
                        </div>
                        <div>
                            {{ $t('connection.phone') }}：{{ $store.state.fromObj.qq }}
                        </div>
                    </div>
                    <div class="text2 mt-30 mt-10">
                        <div>
                            WhatsApp：{{ $store.state.fromObj.whats_app }}
                        </div>
                        <div>
                            Email：{{ $store.state.fromObj.email }}
                        </div>
                    </div>
                    <div class="text2 ">
                        <div>
                            Telegram：{{ $store.state.fromObj.telegram }}
                        </div>
                        <div>
                            Facebook：{{ $store.state.fromObj.facebook }}
                        </div>
                    </div>
                    <div class="text2 mt-30 mt-10">
                        <div>
                            {{ $t('connection.address') }}：{{ $store.state.fromObj.address }}
                        </div>
                    </div>
                    <div class="ewmBox mt-10">
                        <div class=" f-n">
                            <img class="ewm" :src="$store.state.fromObj.wechat_qrcode">
                            <div class="box1">{{ $t('connection.account') }}</div>
                        </div>
                        <div class=" f-n">
                            <img class="ewm" :src="$store.state.fromObj.whats_app_qrcode">
                            <div class="box1">WhatsApp</div>
                        </div>
                        <div class=" f-n">
                            <img class="ewm" :src="$store.state.fromObj.telegram_qrcode">
                            <div class="box1">Telegram{{ $t('connection.code') }}</div>
                        </div>
                    </div>
                    <!--                    <div class="atlasBox">-->
                    <!--                        <img :src="$store.state.fromObj.con_img">-->
                    <!--                    </div>-->
                    <div id="container"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'

window._AMapSecurityConfig = {
    securityJsCode: 'a80440725f1600f986ac47456c359ef2'
}
import '../assets/css/common.css'
import '@/views/scss/connection.scss'

export default {
    name: "Connection",
    data() {
        return {
            bannerList: [],
            map: null
        }
    },
    mounted() {
        //DOM初始化完成进行地图初始化
        this.initMap()
    },
    created() {
        this.Interface()
    },
    methods: {
        initMap() {
            AMapLoader.load({
                key: '6004f13831a1f540deb21716f9731dcd', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Marker', 'AMap.Zoom', 'AMap.ToolBar'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                AMapUI: {
                    plugins: ['misc/PositionPicker'], // 添加组件
                },
            })
                .then(AMap => {
                    this.map = new AMap.Map('container', {
                        //设置地图容器id
                        viewMode: '3D', //是否为3D地图模式
                        zoom: 16, //初始化地图级别
                        center: [Number(this.$store.state.fromObj.longitude), Number(this.$store.state.fromObj.latitude)],
                    })
                    // 修改地图中心点
                    this.map.setCenter([Number(this.$store.state.fromObj.longitude), Number(this.$store.state.fromObj.latitude)]);
                    // 创建一个 Marker 实例：
                    var marker = new AMap.Marker({
                        position: new AMap.LngLat(Number(this.$store.state.fromObj.longitude), Number(this.$store.state.fromObj.latitude)),   // 经纬度对象，也可以是经纬度构成的一维数组[经度, 纬度]
                        title: this.$store.state.fromObj.address,
                        label: {
                            content: '<div style="color: blue; font-size: 14px; font-weight: bold; border: none;">' + this.$store.state.fromObj.title + '</div>',
                            offset: new AMap.Pixel(0, -30), // 调整标签的偏移量，使其显示在标记的上方
                        }
                    });
                    // 将创建的点标记添加到已有的地图实例：
                    this.map.add(marker);
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async Interface() {
            //公司信息
            await this.$axios.post('pcapi/index/banner', {
                type: 7
            }).then(res => {
                if (res.data.code == 1) {
                    this.bannerList = res.data.data
                }
            })
        },
    }


}
</script>
<style>

</style>
